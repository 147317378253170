'use client';

import { useContext, useState, useEffect } from 'react';
import { CartContext } from '@/app/components/cart/cart-context';
import CartItem from '@/app/components/cart/cart-item';
import { removeCartItem } from './cart-actions';

export default function CartItemList() {
  const [cart, dispatch] = useContext(CartContext);
  const [expiredCartKey, setExpiredCartKey] = useState([]);

  useEffect(() => {
    const removeItem = async (cart_key) => {
      if (cart_key) {
        const { data, error } = await removeCartItem(cart_key);
        if (data?.cart) {
          dispatch({
            type: 'RELOAD_CART_FROM_DB',
            payload: data?.cart,
          });
        }
      }
    };
    if (expiredCartKey.length > 0) {
      expiredCartKey?.map((key) => removeItem(key));
    }
  }, [expiredCartKey?.length]);

  return (
    <ul role="list" className="w-full mt-10 ">
      {cart &&
        cart?.contents?.nodes?.map((item, i) => {
          return (
            <li key={i} className="relative w-full ml-0 flex justify-between ">
              <CartItem
                item={item}
                setExpiredCartKey={setExpiredCartKey}
                expiredCartKey={expiredCartKey}
              />
            </li>
          );
        })}
    </ul>
  );
}
