import { Children, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseIcon } from '@/app/components/icons/close-icon';

const RightSlideDrawer = ({ title, children, open, setOpen }) => {
  // const [open, setOpen] = useState(false);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[120]"
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-1500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-1500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen md:max-w-[30vw] bg-brand">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0  flex -ml-[25px]  md:-ml-[35px] h-28 sm:p-2 bg-brand rounded-bl-[50px] rounded-tl-3xl">
                      <button
                        type="button"
                        className="relative flex justify-center top-3 rounded-sm text-gray-700 hover:text-black h-10 w-8"
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon className="h-5 w-5 text-black" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll dark:bg-dark-300 pt-2 pb-6 mt-2 rounded-tl-xl shadow-xl -mr-2">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="w-full text-xl font-bold text-light flex p-3  uppercase">
                        <div className="">{title}</div>
                      </Dialog.Title>
                    </div>
                    {/* Content */}
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RightSlideDrawer;
