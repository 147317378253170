'use client';
// import { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import Image from '@/app/components/ui/image';
import AnchorLink from '@/app/components/ui/links/anchor-link';
import placeholder from '@/assets/images/placeholders/product.svg';
import SiteConfig from '@/config/siteconfig';
import Processing from '../ui/loader/processing';

function addMinutes(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <span className="mr-0">{`Expired`}</span>;
  } else {
    // Render a countdown
    return (
      <span className="mr-0">{`Expires in  ${hours}:${minutes}:${seconds}`}</span>
    );
  }
};

export default function CartItem({ item, setExpiredCartKey, expiredCartKey }) {
  const { product, quantity, total, extraData, key: cart_key } = item;

  const cartAddedDateTime = extraData?.filter(
    (x) => x.key == 'card_add_time',
  )[0]?.value;
  const isLotteryInstantWins = extraData?.filter(
    (x) => x.key == 'lottery_instant_wins',
  )[0]?.value;

  return (
    <div className="mb-10 font-interstate">
      <div className="flex gap-4">
        <div className=" relative h-[75px] w-[100px] ">
          <Image
            alt={product?.node?.name}
            fill
            src={product?.node?.image?.sourceUrl ?? placeholder}
            className="object-cover rounded-lg"
          />
        </div>
        <div>
          <h5 className="font-bold text-sm text-brand uppercase font-interstate  mb-1 w-4/5">
            {product?.node?.name}
          </h5>
          <div className="w-full flex justify-between font-bold font-interstate pt-3 text-light-base dark:text-white gap-5">
            <span className="font-normal text-sm text-brand-gray_1 ">
              {`${quantity} Ticket(s)`}
            </span>
            <h5 className="font-bold text-base text-brand font-interstate">
              {total}
            </h5>
          </div>
        </div>
      </div>
      <div className="w-4/5 flex justify-between my-5">
        <div className="text-red-500 flex justify-center uppercase font-bold ">
          <Countdown
            date={addMinutes(
              new Date(cartAddedDateTime),
              SiteConfig?.CART_EXPIRY_MIN || 10,
            )}
            renderer={renderer}
            onComplete={() => setExpiredCartKey([...expiredCartKey, cart_key])}
          />
        </div>

        {cart_key && expiredCartKey?.includes(cart_key) ? (
          <div className=" flex gap-3 uppercase">
            <span>Removing</span>
            <Processing />
          </div>
        ) : (
          <div
            className=" flex justify-center cursor-pointer px-4 uppercase rounded-2xl items-center text-sm border-[1px]  bg-red-400 text-white"
            onClick={() => setExpiredCartKey([...expiredCartKey, cart_key])}
          >
            Remove
          </div>
        )}
      </div>

      {isLotteryInstantWins == 'no' && (
        <div className="w-full flex flex-wrap  gap-2 uppercase text-dark-100 dark:text-white font-interstate font-bold text-sm">
          <span className="text-nowrap text-black dark:text-brand">
            Ticket number(s) :
          </span>
          {extraData[0].value
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .sort(function (a, b) {
              return a - b;
            })
            .map((t, i) => (
              <span key={i}>{t}</span>
            ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="items-start w-full gap-1 py-3">
        <div className="flex w-full items-start gap-4 py-3">
          <div className="relative aspect-[5/3.4] w-16 lg:w-20 flex-shrink-0 border border-light-300 bg-light-300 dark:border-0 dark:bg-dark-500 ">
            <Image
              alt={product?.node?.name}
              fill
              src={product?.node?.image?.sourceUrl ?? placeholder}
              className="object-cover"
            />
          </div>
          <div className="w-[calc(100%-130px)] text-13px font-medium ">
            <h3 className="truncate text-dark dark:text-light">
              <AnchorLink
                href={`/product/${product?.node?.slug}`}
                className="transition-colors hover:text-brand"
              >
                {product?.node?.name}
              </AnchorLink>
            </h3>

            <p className="flex items-center gap-1">
              <span className="rounded-2xl bg-light-300 p-1.5 font-semibold uppercase leading-none text-brand dark:bg-dark-500">
                {total}
              </span>
              <span className=" text-brand-dark dark:text-white rounded-2xl  p-1.5 text-[11px]">
                {quantity} Ticket (s)
              </span>
            </p>
          </div>
        </div>
        <div className="w-full flex justify-between pt-3 text-light-base  dark:text-white">
          <div className="text-red-400 text-[14px] font-bold uppercase mr-5">
            <Countdown
              date={addMinutes(
                new Date(cartAddedDateTime),
                SiteConfig?.CART_EXPIRY_MIN || 10,
              )}
              renderer={renderer}
              onComplete={() =>
                setExpiredCartKey([...expiredCartKey, cart_key])
              }
            />
          </div>
          <div>
            {cart_key && expiredCartKey?.includes(cart_key) ? (
              <Processing />
            ) : (
              <div
                className="h-6  hover:text-brand-dark dark:text-white relative cursor-pointer px-3 rounded-xl bg-light-300 p-1.5 text-[12px] font-normal  leading-none dark:bg-dark-500"
                onClick={() => setExpiredCartKey([...expiredCartKey, cart_key])}
              >
                Remove
              </div>
            )}
          </div>
        </div>
        {isLotteryInstantWins == 'no' && (
          <>
            <span>Ticket number :</span>
            <div className="mt-2 flex flex-wrap items-center  gap-x-1 text-xs font-normal ">
              {extraData[0].value
                .replace('[', '')
                .replace(']', '')
                .split(',')
                .sort(function (a, b) {
                  return a - b;
                })
                .map((t, i) => (
                  <span
                    key={i}
                    className="bg-brand m-1 flex h-9 w-9 items-center justify-center rounded-full text-black"
                  >
                    {t}
                  </span>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
